
$theme-colors: (
        "bluerim":#273646,
        "greenrim":#8cc148,
        "bluerim-dropdown":#e9ecef
);

@import "~bootstrap/scss/bootstrap";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  height:100%;
}
html {
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
