@import "~leaflet/dist/leaflet.css";
@import "./media-queries.scss";

canvas {
  max-width:300px;
}

.main-card {
  max-width:500px;
  margin-left:auto;
  margin-right:auto;
}

.mastfoot {
  color:#6e6e6e;
}

.form-control-ssid,.form-control-psk {
  font-family: monospace;
  font-size: 1.3rem;
  background-color: #fff !important;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.ssid-dropdown.bluerim-ssid-dropdown {
  /*max-height: 38px;*/
  border-radius:0;


  .p-dropdown-label {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    background-color: #e9ecef;
    /*width:200px;*/
    min-width: 170px;
    color:#495057;
    border-radius: 0;
  }
  .p-dropdown-trigger {
    background-color: #e9ecef;
  }
}

.input-group-block {
  display:block;
}

.word-prefix {
  color: #640d0d;
  width:unset;
}
.word1 {
  color: #04307a;
  width:unset;
}
.word2 {
  color: #640d0d;
  width:unset;
}

.common-input {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.word-group {
  @extend .common-input;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  min-width: 15.5rem;
  flex: 1 1 auto;
}

.spinner-input {
  @extend .common-input;
  max-height: 38px;
  min-width: 15rem;
}

/*
.ssid-dropdown > * {
  min-width:15rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  background-color:#e9ecef;
  border-color: #ced4da;
  color:#495057;
  border-radius:0;
}
 */

  @keyframes bluerim-progress-spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes bluerim-progress-spinner-color {
  100%,
  0% {
      stroke: #273646;
  }
  40% {
    stroke: #273646;
    }
  66% {
    stroke: #273646;
  }
  80%,
  90% {
    stroke: #273646;
  }
}

.bluerim-progress-spinner {
  .p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #273646;
    animation: bluerim-progress-spinner-dash 1.5s ease-in-out infinite, bluerim-progress-spinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.bluerim-progress-spinner.bluerim-progress-spinner-small {
  width: 28px;
  height: 28px;
}
.bluerim-progress-spinner.bluerim-progress-spinner-large {
  width: 300px;
  height: 300px;
}
.br-qr-code {
  margin-top: 1em;
}

  @include media("<=515px") {
    .br-icon-label {
      display:none;
    }
    .card-body {
      padding: 0.5rem;
    }
    .br-qr-code {
      margin-top: 0.5rem;
      max-width: 200px;
    }
    .ssid-dropdown.bluerim-ssid-dropdown {
      min-width: 130px;
      /*max-height: 30px;*/
      font-size: 10pt;
      .p-inputtext {
        font-size: 10pt;
        padding: 0.5em;
      }
      .p-dropdown-label {
        min-width: 120px;
      }
    }
    .ssid-input {
      max-height: 30px;
      font-size: 10pt;
    }
    .input-group-block.btn {
      max-height:30px;
    }
    .word-group {
      font-size: 10pt;
      min-width: 1%;
      max-height: 30px;
    }
    .btn {
      font-size: 0.75em;
    }
  }

  @include media(">=815px", "<desktop") {
  }

  @include media(">=desktop") {
  }

  @include media("retina2x") {
  }
